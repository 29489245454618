<template>
    <div class="hide-on-print">
        <v-app-bar color="white" app elevation="1" outlined>
            <v-row>
                <v-col cols="6" md="4" class="d-flex align-center justify-start">
                    <v-btn icon @click.stop="drawer = !drawer" v-if="loggedin && !drawer">
                        <v-icon large>mdi-menu</v-icon>
                    </v-btn>
                    <v-btn icon @click.stop="drawer = !drawer" v-else-if="loggedin && drawer">
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                    <v-btn icon v-else @click="$router.push('/home')">
                        <v-icon large>mdi-home</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4" class="d-none d-md-flex align-center justify-center">
                    <v-toolbar-title class="d-flex align-center justify-center">
                        <v-img v-if="displayLogo" src="@/assets/images/logo/logo_lineaire.svg" contain style="width:100%;max-width: 250px;"/>
                    </v-toolbar-title>
                </v-col>
                <v-col cols="6" md="4" class="d-flex align-center justify-end">
                    <v-btn icon v-if="loggedin && $route.params.id" @click="triggerShare">
                        <v-icon>mdi-share</v-icon>
                    </v-btn>
                    <v-btn icon v-if="loggedin && $route.params.id && $route.name =='Responses'" @click="triggerView">
                        <v-icon>mdi-eye-outline</v-icon>
                    </v-btn>
                    <v-btn icon v-if="loggedin && $route.params.id" @click="triggerEdit">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon v-if="loggedin && $route.params.id" @click="triggerCopy">
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn icon v-if="loggedin && $route.params.id && $route.name =='Formulaire'" @click="triggerResponses">
                        <v-icon>mdi-chart-bar</v-icon>
                    </v-btn>
                    <v-btn icon v-if="loggedin && $route.params.id" @click="triggerDelete">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn color="accent" icon v-if="loggedin" @click="logout">
                        <v-icon color="accent">mdi-logout</v-icon>
                    </v-btn>
                    <v-btn color="primary" icon v-else @click="loginDialog = true">
                        <v-icon>mdi-lock-open</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" app hide-overlay v-if="loggedin" class="elevation-0" width="350">
            <v-list nav dense>
                <v-list-item-group>
                    <v-list-item class="py-1" @click="$router.push('/home')">
                        <v-list-item-title>
                            <span class="d-flex flex-column align-center justify-center">
                                <v-img src="@/assets/images/logo/logo_carre.svg" contain style="width:100%;max-width: 200px;"/>
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <p class="pt-3 py-1 my-0 font-weight-light">Liste des formulaires :</p>
                    <v-list-item class="py-2" v-for="form in forms" @click="goToForm(form)">
                        <v-list-item-title>
                            <span class="d-flex flex-rox align-center justify-start">
                                <v-icon color="primary" class="pr-3">mdi-check-circle-outline</v-icon>
                                <h3 style="width: 300px; text-wrap: wrap;" v-text="form.title"></h3>
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item class="py-1">
                        <v-list-item-title>
                            <span class="d-flex flex-rox align-center justify-start" @click="addForm">
                                <v-icon color="indigo" class="pr-3">mdi-plus</v-icon>
                                <h3>Ajouter</h3>
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-dialog v-model="loginDialog" persistent max-width="500px" transition="dialog-transition" >
            <v-card>
                <v-card-title>
                    <span class="headline">Connexion</span>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" icon @click="closeLoginModal">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-form @submit="login">
                        <v-text-field type="password" label="Mot de passe" v-model="password"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="login">Connexion</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"

import Form from "@/classes/Form.js"

export default {
    name: "Navigation",
    props : ['loggedin'],
    setup() {
        return {
            auth : getAuth(),
        }
    },
    data() {
        return {
            unsub : [],
            drawer: false,
            group: null,
            loginDialog : false,
            password : "",
            forms : [],
            displayLogo : true
        }
    },
    created() {
        this.unsub.push(Form.listenAll( forms => {
            forms.sort((a,b) => a.title.localeCompare(b.title))
            this.forms = forms
        }))
    },
    methods : {
        toggleLogo(bool){
            this.displayLogo = bool
        },
        closeLoginModal(){
            this.password = ""
            this.loginDialog = false
        },
        login(event){
            event.preventDefault()
            signInWithEmailAndPassword(this.auth, "admin@admin.admin", this.password)
            this.password = ""
            this.loginDialog = false
        },
        logout(){
            this.auth.signOut()
        },
        async addForm(){
            let newForm = new Form(null, "Nouveau formulaire", [], "")
            await newForm.save()
            this.goToForm(newForm)
        },
        goToForm(form){
            this.$router.push("/form/"+form.id)
            this.$forceUpdate()
        },
        triggerEdit(){
            this.$emit("edit")
        },
        triggerCopy(){
            this.$emit("copy")
        },
        triggerDelete(){
            this.$emit("delete")
        },
        triggerResponses(){
            this.$emit("responses")
        },
        triggerView(){
            this.$emit("view")
        },
        triggerShare(){
            this.$emit("share")
        }
    },
    destroyed() {
        this.unsub.forEach(unsub => unsub())
    }
}
</script>