<template>
    <span class="hide-on-print">
        <v-footer class="d-flex justify-space-around align-center transparent">
            <i class="text-center" v-text="'Copyright ©'+new Date().getFullYear()+' LoesusPedagogia'"></i>
        </v-footer>
    </span>
</template>

<script>

export default {
    name: "Footer",
}

</script>