import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "forms"

class Form{
    constructor(id, title, sections, code, color = false, textColor = false, logo = false){
        this.id = id
        this.title = title
        this.sections = sections
        this.code = code
        this.color = color
        this.textColor = textColor
        this.logo = logo
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let forms = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_form = new Form(doument.id, data.title, data.sections, data.code, data.color, data.textColor, data.logo)
            forms.push(tmp_form)
        })

        return forms
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let form = new Form(response.id, data.title, data.sections, data.code, data.color, data.textColor, data.logo)

        return form
    }

    static async getByCode(code){
        if(code == null){
            return []
        }else{
            let collectionRef = collection(db, collectionName)
            let documentQuery = query(collectionRef, where("code", "==", code))
            let response = await getDocs(documentQuery)
    
            let forms = []
    
            response.forEach(document => {
                let data = document.data()
                let rmp_Response = new Form(document.id, data.title, data.sections, data.code, data.color, data.textColorr, data.logo)
                forms.push(rmp_Response)
            })
            if(forms.length == 0){
                return null
            }else{
                return forms[0]
            }
        }
    } 
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let forms = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_form = new Form(document.id, data.title, data.sections, data.code, data.color, data.textColorr, data.logo)
                forms.push(tmp_form)
            })
            
            if(callback != null){
                callback(forms)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let form = new Form(snapshot.id, data.title, data.sections, data.code, data.color, data.textColor, data.logo)
            
            if(callback != null){
                callback(form)
            }
        })
        return unsub
    }

    async save(){   
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                title: this.title,
                sections: this.sections,
                code: this.code,
                color: this.color,
                textColor: this.textColor,
                logo: this.logo
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                title: this.title,
                sections: this.sections,
                code: this.code,
                color: this.color,
                textColor: this.textColor,
                logo: this.logo
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Form