const themeConfig = {
    darkTheme: false, 
	themes: {
		light: {
			primary: "#006533",
			secondary: "#FFDE00",
			accent: "#E30614",
            //swal
            swalLight: "#FFFFFF",
            swalDark: "#0C0C0C",
		},
	},
}

export default themeConfig
