import Vue from "vue"
import VueRouter from "vue-router"

import { getAuth } from "firebase/auth"
//ss_admin

Vue.use(VueRouter)

let user = null
let loggedin = false

getAuth().onAuthStateChanged((firebaseUser) => {
    user = firebaseUser
    if(user){
        loggedin = true
    }else{
        loggedin = false
    }
})

const routes = [
	{
		path: "/",
		redirect: "home",
	},
    {
        path: '/home',
        name: 'Home',
        component: () => import("@/views/Home.vue"),
        meta: {
            title: 'Accueil',
        }
    },
    {
        path: '/form/:id',
        name: 'Formulaire',
        component: () => import("@/views/Form.vue"),
        meta: {
            title: 'Formulaire',
        }
    },
    {
        path: '/responses/:id',
        name: 'Responses',
        component: () => import("@/views/Response.vue"),
        meta: {
            title: 'Responses',
        }
    },
    {
        path: '/print/:id',
        name: 'Print',
        component: () => import("@/views/Print.vue"),
        meta: {
            title: 'Print',
        }
    },
    {
        path: '*',
        name: 'Error404',
        component: () => import("@/views/Error404.vue"),
        meta: {
            title: '404',
        }
    },
]



const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
})

router.beforeEach((to, from, next) => {
    next()
})
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = "Smile Survey" + (to.meta.title ? " - " + to.meta.title : "");
    });
});

export default router
