import { initializeApp } from 'firebase/app'

let config = {
    apiKey: "AIzaSyABCRGwoaC34duAmfhGa-1bl0BLJt0q6zM",
    authDomain: "smileandsurvey.firebaseapp.com",
    projectId: "smileandsurvey",
    storageBucket: "smileandsurvey.appspot.com",
    messagingSenderId: "824335262163",
    appId: "1:824335262163:web:fb494eaa0f2eeae076cf26"
}
const firebaseApp = initializeApp(config)

export default firebaseApp