<template>
    <v-app>
        <Navigation ref="nav" :loggedin="loggedin" @copy="triggerCopy" @edit="triggerEdit" @delete="triggerDelete" @responses="triggerResponses" @view="triggerView" @share="triggerShare"></Navigation>
        <v-main>
            <v-container>
                <router-view :loggedin="loggedin" ref="currentView" @toggleLogo="toggleLogo"/>
            </v-container>
        </v-main>
        <Footer></Footer>
    </v-app>
</template>

<script>
import { getAuth} from "firebase/auth"
import Navigation from "@/components/Navigation.vue"
import Footer from "@/components/Footer.vue"

export default {
    name: "App",
    components: {
        Navigation,
        Footer,
    },
    data() {
        return {
            unsub : [],
            loggedin : false,
        }
    },
    created() {
        this.unsub.push(
            getAuth().onAuthStateChanged(firebaseUser => {
                if (firebaseUser) {
                    this.loggedin = true
                } else {
                    this.loggedin = false
                }
            })
        )
    },
    methods : {
        toggleLogo(bool){
            this.$refs.nav.toggleLogo(bool)
        },
        triggerEdit(){
            if(typeof this.$refs.currentView.triggerEdit == "function"){
                this.$refs.currentView.triggerEdit()
            }
        },
        triggerCopy(){
            if(typeof this.$refs.currentView.triggerCopy == "function"){
                this.$refs.currentView.triggerCopy()
            }
        },
        triggerDelete(){
            if(typeof this.$refs.currentView.triggerDelete == "function"){
                this.$refs.currentView.triggerDelete()
            }
        },
        triggerResponses(){
            if(typeof this.$refs.currentView.triggerResponses == "function"){
                this.$refs.currentView.triggerResponses()
            }
        },
        triggerView(){
            if(typeof this.$refs.currentView.triggerView == "function"){
                this.$refs.currentView.triggerView()
            }
        },
        triggerShare(){
            if(typeof this.$refs.currentView.triggerShare == "function"){
                this.$refs.currentView.triggerShare()
            }
        },
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>
